/* eslint-disable react/no-unused-prop-types */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RenderToContext from '../../RenderToContextDesign1'
import RenderToContext2 from '../../RenderToContextDesign2'
import RenderToContext3 from '../../RenderToContextDesign3'

import {RoutingUtils} from 'ufp-core/lib/modules/react-redux-hash-router4/util'

var equal = require('deep-equal')

class CanvasView extends Component {

    static propTypes = {
        onClick: PropTypes.func,

        maxIterations: PropTypes.number,
        color: PropTypes.oneOf(['black', 'white']),
        color1: PropTypes.shape({
            r: PropTypes.number,
            g: PropTypes.number,
            b: PropTypes.number,
            a: PropTypes.number
        }),
        color2: PropTypes.shape({
            r: PropTypes.number,
            g: PropTypes.number,
            b: PropTypes.number,
            a: PropTypes.number
        }),
        design: PropTypes.oneOf(['design1', 'design2']),
        height: PropTypes.number,
        index: PropTypes.number,
        width: PropTypes.number,
        quality: PropTypes.number,
        startOfAlternation: PropTypes.number,
        seeds: PropTypes.arrayOf(PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number

        })),
        text1: PropTypes.string,
        text2: PropTypes.string,
        text3: PropTypes.string
    }
    static defaultProps = {
        color: 'white',
        onClick: undefined,
        /*
         text1Pos: {
         size: 2.6,
         x: 4,
         y: 55

         },
         text2Pos: {
         size: 5,

         x: 4,
         y: 90

         },
         text3Pos: {

         x: 4,
         y: 92

         },
         text4Pos: {

         size: 5,
         x: 43,
         y: 90

         },

         */
        showQrCode: false,
        text3Pos: {

            visible: false

        }
        // fontSize: 7
    }

    // static getDerivedStateFromProps(props, state) {
    //     return {quality: props.quality || state.quality}
    // }

    constructor(props) {
        super(props)
        console.log('CanvasView Constructor of  called')
        this.myRef = React.createRef()
        this.state = {
            dataURL: undefined,
            quality: props.quality || 2,
            design: props.design
        }
    }

    componentDidUpdate() {
        // if (!this.canvasDirty) {
        //     this.setState({canvasDirty: true})
        // }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // make brutal deep compare here, not utilizing redux because we are feeded from another component not through state
        var result = !equal(this.props, nextProps) || !equal(this.state, nextState)

        // console.log('Should update ? ', result, nextProps, nextState)
        if (this.myRef.current) {
            const canvas = this.myRef.current
            canvas.height = canvas.getBoundingClientRect().width
            console.log('width is ', canvas.getBoundingClientRect().width)
            if (this.state.lastSize != canvas.getBoundingClientRect().width) {
                result = true
            }
        }
        console.log('CanvasView should component update', result, nextProps, nextState)
        return result
    }

    componentDidMount = () => {
        console.log('CanvasView component did mount')
        // this.textInput.current.focusTextInput();
        // this.draw()
        //  setTimeout(this.draw, 1)
        this.setState({
            canvasDirty: false
        })
    }

    drawUsingOffscreenCanvas = () => {
        console.log('CanvasView drawUsingOffscreenCanvas', this, this.myRef.current.clientWidth)
        var canvas = document.createElement('canvas')

        canvas.width = this.myRef.current.clientWidth / this.state.quality
        canvas.height = this.myRef.current.clientWidth / this.state.quality
        const ctx1 = canvas.getContext('2d')

        var useprops = Object.assign({}, this.props)
        if (this.props.color === 'black') {
            useprops.color1 = {
                r: 0,
                g: 0,
                b: 0
            }
        }
        useprops.width = this.myRef.current.clientWidth / this.state.quality
        useprops.height = this.myRef.current.clientWidth / this.state.quality

        if (this.state.design === 'design2') {
            RenderToContext2.render({
                context: ctx1,
                props: useprops
            })
        } else if (this.state.design === 'design3') {
            RenderToContext3.render({
                context: ctx1,
                props: useprops
            })
        } else {
            RenderToContext.render({
                context: ctx1,
                props: useprops
            })
        }

        this.setState({dataURL: canvas.toDataURL()})
    }

    handleClick = () => {
        if (this.props.onClick) {
            this.props.onClick()
        }
        //   console.log('clicked ', this.props)
        //   RoutingUtils.push(`/detail/${this.props.}`)
    }
    handleGoToPrev = (evt) => {
        RoutingUtils.push(`/${this.props.index - 1}`)
        evt.stopPropagation()
    }
    handleGoToNext = (evt) => {
        RoutingUtils.push(`/${this.props.index + 1}`)
        evt.stopPropagation()
    }
    handlePlus = (evt) => {
        if (this.state.quality < 8) {
            this.setState({
                dataURL: undefined,
                quality: this.state.quality * 2
            })
        }
        evt.stopPropagation()
    }
    switchDesign = (design) => {
        this.setState({
            dataURL: undefined,
            design: design
        })
    }
    handleSwitchDesign = (evt) => {
        switch (this.state.design) {
            case 'design1':
                this.switchDesign('design2')
                break
            case 'design2':
                this.switchDesign('design3')
                break
            default:
                this.switchDesign('design1')
        }
        evt.stopPropagation()
    }
    handleMinus = (evt) => {
        if (this.state.quality > 1) {
            this.setState({
                dataURL: undefined,
                quality: this.state.quality / 2
            })
        }
        evt.stopPropagation()
    }
    setQuality = (quality) => {
        this.setState({
            dataURL: undefined,
            quality: quality
        })
    }

    render() {
        console.log('CanvasView Rendering Mandel View', this.props, this.state)
        if (!this.state.dataURL && this.myRef.current) {
            setTimeout(this.drawUsingOffscreenCanvas, 0)
        }

        return (
            <div className="w3-display-container"
                 onClick={this.handleClick}
                 ref={this.myRef}
                 style={{
                     width: '100%'
                 }}
                 title="click for detail" >
                {this.state.dataURL ?

                    <img src={this.state.dataURL}
                         width="100%" /> : <div style={{
                        width: '100%',
                        height: '1000px'
                    }} >
                        <i className="fas fa-spinner fa-spin w3-display-middle" />
                        Calculating new...</div >}


                <div className="w3-display-hover w3-display-left  w3-mobile" >
                    <i className={'w3-button fas fa-backward  w3-big'}
                       onClick={this.handleGoToPrev}
                       title="previous index" />

                </div >
                <div className="w3-display-hover w3-display-right w3-mobile" >
                    <i className={'w3-button fas fa-forward w3-big'}
                       onClick={this.handleGoToNext}
                       title="next index" />

                </div >

                <div >
                    <div className="w3-left" >
                        <span className={'w3-button  w3-red w3-disabled '}
                                >Design</span >
                        <span className={'w3-button ' + (this.state.design === 'design1' ? 'w3-red' : '')}
                              onClick={() => this.switchDesign('design1')} >ILC</span >
                        <span className={'w3-button ' + (this.state.design === 'design2' ? 'w3-red' : '')}
                              onClick={() => this.switchDesign('design2')} >IC</span >
                        <span className={'w3-button ' + (this.state.design === 'design3' ? 'w3-red' : '')}
                              onClick={() => this.switchDesign('design3')} >AO</span >
                    </div >
                    <div className="w3-right" >
                        <span className={'w3-button  w3-red w3-disabled '}
                        >Quality</span >
                        <span className={'w3-button ' + (this.state.quality === 4 ? 'w3-red' : '')}
                              onClick={() => this.setQuality(4)} >25%</span >
                        <span className={'w3-button ' + (this.state.quality === 2 ? 'w3-red' : '')}
                              onClick={() => this.setQuality(2)} >50%</span >
                        <span className={'w3-button ' + (this.state.quality === 1 ? 'w3-red' : '')}
                              onClick={() => this.setQuality(1)} >100%</span >
                    </div >
                </div >
            </div >

        )
    }

}

export default CanvasView
