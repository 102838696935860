import React, {Component} from 'react'
import RouteNames from '../../routes/RouteNames'
import {HOCRouter, Route, Switch} from 'ufp-core/lib/modules/react-redux-hash-router4/view'
import PropTypes from 'prop-types'
import PageOverview from '../../routes/PageOverview'
import PageView from '../../routes/PageView'
import HomeView from '../../routes/HomeView'
import ImprintView from '../../routes/ImprintView'
import Menu from './Menu'
import GoogleAdsense from './GoogleAdsense'
import CookieConsent from './CookieConsent'

class Root extends Component {

    render() {
        console.log('Root rendering Root', this.props, this.context)
        return [
            //   <React.StrictMode>

            <div className="w3-bar w3-theme w3-bottom "
                 key="menu"
                 style={
                     {
                         position: 'fixed',
                         zIndex: '1'
                     }
                 } >
                <a className="w3-bar-item w3-xlarge w3-button w3-hover-black w3-medium"
                   href="#/" >
                    <i className="fa fa-heart w3-text-red w3-hover-text-orange" />
                    &nbsp;I love chaos
                </a>
                <div className="w3-bar-item   w3-right" ><Menu location={this.props.location} /></div>
            </div>,

            <div className="w3-content "
                 key="content"
                 style={{
                     paddingBottom: '54px'
                 }} >

                <Switch key="two"
                        location={this.props.location} >
                    <Route component={PageOverview}

                           path={RouteNames.PageOverview.route} />
                    <Route component={PageView}
                           exact
                           path={RouteNames.PageDetail} />
                    <Route component={PageView}
                           exact
                           path={RouteNames.PageDetailOld} />
                    <Route component={ImprintView}

                           path={RouteNames.Imprint} />
                    <Route component={HomeView}
                           path={RouteNames.Home} />
                </Switch>

                <GoogleAdsense key="ads" />
            </div>
            ,
            <CookieConsent key="cookie" />
        ]
    }

}
Root.propTypes = {
    location: PropTypes.object.isRequired

}
export default HOCRouter()(Root)
