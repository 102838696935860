/* eslint-disable react/no-unused-prop-types */
var qr = require('qr-image')
var merge = require('deepmerge')

var GOLDEN_RATIO = 1.61803398875
var GOLDEN_RATIO_INV = 1 / 1.61803398875

if (merge.default) {
    // strange webpack import behaviour
    console.log('MERGE IS ', merge)
    merge = merge.default
}
function lerp(x, y, a) {
    return x * (1.0 - a) + y * a
}

function mandelbrot(z, c) {
    var result = {}
    // z = z*z + c
    result.x = (  z.x * z.x - z.y * z.y ) + c.x
    result.y = (2.0 * z.x * z.y) + c.y
    return result
}

function length(z) {
    return Math.sqrt(z.x * z.x + z.y * z.y)
}

function rotate(point, degrees) {
    const radian = degrees / 180 * Math.PI
    var result = {}
    result.x = point.x * Math.cos(radian) - point.y * Math.sin(radian)
    result.y = point.x * Math.sin(radian) + point.y * Math.cos(radian)
    return result
}

function add(x, y) {
    return {
        x: x.x + y.x,
        y: x.y + y.y
    }
}

function drawGoldenRatio(ctx, width, height) {
    const widthMid = width * GOLDEN_RATIO_INV
    const heightMid = width * GOLDEN_RATIO_INV

    ctx.beginPath()
    ctx.moveTo(widthMid, 0)
    ctx.lineTo(widthMid, height)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(width - widthMid, 0)
    ctx.lineTo(width - widthMid, height)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(0, heightMid)
    ctx.lineTo(width, heightMid)
    ctx.stroke()

    ctx.beginPath()
    ctx.moveTo(0, height - heightMid)
    ctx.lineTo(width, height - heightMid)
    ctx.stroke()
}

function iterate(coordinate, currentProps) {
    const c = coordinate
    var z = {
        x: 0.0,
        y: 0.0
    }
    var i
    // console.log('iteration and z start',z,c)
    for (i = 0; i < currentProps.maxIterations; i++) {
        //    console.log('iteration and z',i,z,c)
        z = mandelbrot(z, c)
        //      console.log('length z',this.length(z),z)

        if (i > currentProps.startOfAlternation && currentProps.seeds.length > 0) {
            // console.log('adding0 z', seeds[i % seeds.length])
            z = add(z, currentProps.seeds[i % currentProps.seeds.length])
        }

        if (length(z) > 4)
            break
    }

    const offset = 10
    if (i < offset) {
        // we want pure white outside,
        return 0
    } else {
        return (i - offset) / (currentProps.maxIterations - offset)
    }
}

// setpixel

function setPixel(ctx, x, y, c) {
    ctx.fillStyle = `rgba(${c.r},${c.g},${c.b},${c.a})`
    ctx.fillRect(x, y, 1, 1)
}

function drawQRCode(ctx, string, x = 100, y = 100, width = 100, height = 100, currentProps) {
    console.log('RenderToContext Drawing QR Code ', string, x, y, width, height)

    x = Math.floor(x)
    y = Math.floor(y)
    var qrSvg = qr.matrix(currentProps.text3, {ec_level: 'H'})
    ctx.fillStyle = 'rgba(255,255,255,1)'


    const step = width / qrSvg.length
    ctx.fillRect(x - step, y - step, qrSvg.length * step + step * 2, qrSvg.length * step + step * 2)

    for (var i = 0; i < qrSvg.length; i++) {
        for (var k = 0; k < qrSvg[i].length; k++) {
            const val = qrSvg[i][k]

            var posx = x + step * i
            var posy = y + step * k
            if (val) {
                ctx.fillStyle = 'rgba(0,0,0,1)'
                ctx.fillRect(posx, posy, step, step)
                //    console.log('Filling rect',i,k,Math.round(x+i*width) , y+k*height, stepx,stepy)
            } else {
                ctx.fillStyle = 'rgba(255,255,255,1)'
                ctx.fillRect(posx, posy, step, step)
            }
        }
    }
}

module.exports = {
    GOLDEN_RATIO,
    GOLDEN_RATIO_INV,
    drawGoldenRatio,
    drawQRCode,
    setPixel,
    iterate,
    rotate,
    add,
    lerp
}
