/**
 * the manifest.js defines the properties of the ufp-module
 * @type {{name: string}}
 */

import ReactGA from 'react-ga'
import JSUtils from 'ufp-core/lib/utils/JSUtils'
import {EpicRunfest, registerEpic} from 'ufp-core/lib/modules/epic'
import Epics from './Epics'
import Constants from '../Constants'

var trackingId = null
const Runfest = {
    name: 'google-analytics-runfest',
    description: 'super awesome analytics runfest',
    configure: ({
        GOOGLE_ANALYTICS_TRACKING_ID = JSUtils.ThrowParam('Google Analytics Tracking ID is wrong')
    }) => {
        trackingId = GOOGLE_ANALYTICS_TRACKING_ID
    },
    onRegistered: ({UfpCore}) => {
        UfpCore.registerRunfest(EpicRunfest)
        registerEpic({epic: Epics.changeRouteForGoogleAnalyticsEpic})

        // initialize GA
        console.log('ga-initialize', Constants.TRACKING.GOOGLE_ANALYTICS_TRACKING_ID)
        const GADeactivedId = Constants.COOKIES.GOOGLE_ANALYTICS_DEACTIVATED + '-' + Constants.TRACKING.GOOGLE_ANALYTICS_TRACKING_ID
        if (document.cookie.indexOf(GADeactivedId + '=true') > -1) {
            window[GADeactivedId] = true
        }
        else {
            ReactGA.initialize(trackingId, {debug: true})
            ReactGA.set('anonymizeIp', true)
        }
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }

}

export default Runfest
