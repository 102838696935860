import React from 'react'
import SeedMachine from '../../app/mandel/SeedMachine'
import {Link} from 'ufp-core/lib/modules/react-redux-hash-router4/view/components'

const Menu = (props) => {
    console.log('Rendering menu', props)

    var hover = 0
    if (props.location && props.location.pathname.indexOf('overview') >= 0) hover = 1
    if (props.location && props.location.pathname.indexOf('detail') >= 0) hover = 2
    if (props.location && props.location.pathname.indexOf('imprint') >= 0) hover = 3

    return ([
        <div className="w3-bar w3-theme"
             key="menu" >
            <a className="w3-button w3-small"
               href="https://i-love-chaos.blogspot.com/"
               title="the blog for this site" ><i className="fab fa-blogger" /> blog
            </a>
            <Link className={'w3-bar-item w3-small w3-button w3-hover-white ' + (hover === 0 ? 'w3-red' : '')}
                  to={'/'} >
                <i className="fas fa-home" /> today
            </Link>
            {/*<Link className={'w3-bar-item w3-small w3-button w3-hover-white ' + (hover === 1 ? 'w3-red' : '')}*/}
                  {/*to={'/overview'} >*/}
                {/*<i className="fas fa-table" /> browse*/}
            {/*</Link>*/}
            <Link className={'w3-bar-item w3-small w3-button w3-hover-white ' + (hover === 2 ? 'w3-red' : '')}
                  to={`/${Math.floor(Math.random() * SeedMachine.MAX_INT_RANGE)}`} >
                <i className="fas fa-info" /> random
            </Link>
            <Link className={'w3-bar-item w3-small w3-button w3-hover-white ' + (hover === 3 ? 'w3-red' : '')}
                  to={'/imprint'} >
                <i className="fas fa-gavel" /> imprint
            </Link>
        </div>
    ])
}

export default Menu
