import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import CanvasView from './CanvasView'
import HOCMandel from '../HOCMandel'
import PropTypes from 'prop-types'
import SeedMachine from '../../SeedMachine'
import {RoutingUtils} from 'ufp-core/lib/modules/react-redux-hash-router4/util'
// import Fullscreen from "react-full-screen";
// import Mandelbrot from "../../../../components/Mandelbrot"
class RenderMandelById extends Component {

    static propTypes = {
        index: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number

    }
    static defaultProps = {
        index: 0,
        width: 1024,
        height: 1024
    }

    constructor(props) {
        super(props)

        this.state = {
            isFull: false
        }
    }

    goFull = () => {
        this.setState({isFull: true})
    }

    render() {
        const {index} = this.props
        return (
            <div >
                <CanvasView
                    {...this.props} onClick={() => {
                    RoutingUtils.push(`/${index}`)
                }}
                    seeds={SeedMachine.seedMachine(index)}
                    style={{
                        cursor: 'pointer'
                    }} />

                {/*<div >*/}
                {/*<i className="fas fas-right" onClick={this.goFull} >full</i>*/}
                {/*<i className="fas fas-right"  onClick={() => {*/}
                {/*RoutingUtils.push(`/${index}`)*/}
                {/*}} >view</i>*/}
                {/*<Fullscreen enabled={this.state.isFull}*/}
                {/*onChange={(isFull) => this.setState({isFull})} >*/}
                {/*<Mandelbrot*/}

                {/*{...this.props} seeds={SeedMachine.seedMachine(index)}  />*/}

                {/*</Fullscreen>*/}


                {/*</div>*/}
            </div >

        )
    }

}

/*

            <div >
            <i className="fas fas-right" onClick={this.goFull} >full</i>
            <i className="fas fas-right"  onClick={() => {
                RoutingUtils.push(`/${index}`)
            }} >view</i>
            <Fullscreen enabled={this.state.isFull}
                        onChange={(isFull) => this.setState({isFull})} >
                <ShaderThing

                    {...this.props} seeds={SeedMachine.seedMachine(index)}  />

            </Fullscreen>


        </div>
 */
export default HOCMandel()(RenderMandelById)
