import React from 'react'
import Root from './Root'

const AppCreatorFunction = () => {
    return () => {
        return ([
            <Root key="content" />
        ])
    }
}

export default AppCreatorFunction
