/* eslint-disable react/no-unused-prop-types */
const Render = require('./RenderToContext')
var merge = require('deepmerge')

const YPERCENT = Render.GOLDEN_RATIO_INV * 100

if (merge.default) {
    // strange webpack import behaviour
    console.log('MERGE IS ', merge)
    merge = merge.default
}
const defaultProps = {
    showQrCode: true,
    showGoldenRatio: false,
    text1: 'I',
    text2: 'C',
    text3: 'some-id',
    text4: '',
    maxIterations: 125,
    startOfAlternation: 2,
    width: 100,
    height: 100,
    color1: {
        r: 255,
        g: 255,
        b: 255,
        a: 1
    },
    color2: {
        r: 255,
        g: 0,
        b: 0,
        a: 1
    },     mandelbrot: {
        visible: true,
        scale: 1.7,
        rotate: 90,
        translate: {
            x: -0.4,
            y: 0.35
        }
    },
    seeds: [],
    text1Pos: {

        size: 2,
        x: 0,
        y: YPERCENT

    },
    text2Pos: {
        size: 2,
        x: 64,
        y: YPERCENT

    },
    text3Pos: {
        visible: true,
        size: 75,
        x: 3,
        y: YPERCENT + 1

    },
    text4Pos: {

        size: 2 * (Render.GOLDEN_RATIO) * (Render.GOLDEN_RATIO),
        x: 43,
        y: YPERCENT + 25

    }
}

function drawIt(ctx, width, height, currentProps) {
    const text1Pos = currentProps.text1Pos
    const text2Pos = currentProps.text2Pos
    const text3Pos = currentProps.text3Pos
    const text4Pos = currentProps.text4Pos
    for (var i = 0; i < width; i++) {
        for (var j = 0; j < height; j++) {
            var coord = {
                x: (i / width) * 2 - 1,
                y: (j / height) * 2 - 1
            }
            coord.x *= currentProps.mandelbrot.scale
            coord.y *= currentProps.mandelbrot.scale
            coord = Render.rotate(coord, currentProps.mandelbrot.rotate)
            coord = Render.add(coord, currentProps.mandelbrot.translate)

            const iterResult = Render.iterate(coord, currentProps)
            const pixelColor = {
                r: Math.floor(Render.lerp(currentProps.color1.r, currentProps.color2.r, iterResult)),
                g: Math.floor(Render.lerp(currentProps.color1.g, currentProps.color2.g, iterResult)),
                b: Math.floor(Render.lerp(currentProps.color1.b, currentProps.color2.b, iterResult)),
                a:  Render.lerp(currentProps.color1.a, currentProps.color2.a, iterResult)
            }
            Render.setPixel(ctx, i, j, pixelColor)
            //  console.log('ITER RESULT',  i, j,iterResult,pixelColor)
        }
    }

    // use complement color of first color for text color
    ctx.fillStyle = `rgba(${255 - currentProps.color1.r},${255 - currentProps.color1.g},${255 - currentProps.color1.b},1)`
    ctx.font = `${width / text1Pos.size}px 'Bold Times New Roman'`
    ctx.fillText(currentProps.text1, (width / 100) * text1Pos.x, (height / 100) * text1Pos.y)

    ctx.font = `${width / text2Pos.size}px 'Times New Roman`
    ctx.fillText(currentProps.text2, (width / 100) * text2Pos.x, (height / 100) * text2Pos.y)

    ctx.font = `${width / text4Pos.size}px 'Times New Roman`
    ctx.fillText(currentProps.text4, (width / 100) * text4Pos.x, (height / 100) * text4Pos.y)

    if (text3Pos.visible) {
        console.log('Showing text3 code')
        ctx.font = `${width / text3Pos.size}px 'Arial'`
        ctx.fillText(currentProps.text3, (width / 100) * text3Pos.x, (height / 100) * text3Pos.y)
    }
    // last draw qrcode on top
    if (currentProps.showQrCode) {
        console.log('Showing qr code')
        Render.drawQRCode(ctx, currentProps.text3, (width / 100) * 6.6, (width / 100) * 30.5, (width / 100) * 6.3, (width / 100) * 9, currentProps)
    }
    if (currentProps.showGoldenRatio) {
        Render.drawGoldenRatio(ctx, width, height)
    }
    //  console.log('RenderToContext Draw Mandel finished xxxx', currentProps)
}

module.exports = {

    render: ({context, props = defaultProps}) => {
        // console.log('merge is', merge)
        const propsFinal = merge(defaultProps, props)
        // console.log('RenderToContext  Rendering ', propsFinal, props)

        drawIt(context, propsFinal.width, propsFinal.height, propsFinal)
    }

}
