/* eslint-disable react/no-unused-prop-types */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SeedMachine from '../../SeedMachine'
import RenderDateForIndex from './RenderDateForIndex'


class RenderIndexInfo extends Component {

    static propTypes = {
        index: PropTypes.number
    }
    // static getDerivedStateFromProps(props, state) {
    //     return {quality: props.quality || state.quality}
    // }

    constructor(props) {
        super(props)
    }

    render() {
        console.log('CanvasView Rendering Mandel View', this.props, this.state)

        const currentIndex = this.props.index
        var label = ''
        if (currentIndex < 0) {
            label = 'An undeformed Mandelbrot, the mother of all chaos'
        } else if (currentIndex < 1000000) {
            label = (
                <div >Index <b >{Math.floor(currentIndex)}</b > of {Math.floor(SeedMachine.MAX_INT_RANGE)} associated with
                    date <b ><RenderDateForIndex index={currentIndex} /></b >
                    <div > they are used consecutively up until index 1Mio</div >
                </div >)
        } else {
            label = <div >Index <b >{Math.floor(currentIndex)}</b ></div >
        }

        return (
            <div className="w3-bar-item w3-middle" >

                {label}
            </div >

        )
    }

}

export default RenderIndexInfo
