import React, {Component} from 'react'
import './PageOverView.scss'
import PropTypes from 'prop-types'
import MandelRenderView from '../../../app/mandel/view/components/MandelRenderView'
import SeedMachine from '../../../app/mandel/SeedMachine'
import {RoutingUtils} from 'ufp-core/lib/modules/react-redux-hash-router4/util'
import {Link} from 'ufp-core/lib/modules/react-redux-hash-router4/view/components'

export class PageOverView extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    }

    render() {
        console.log('Rendering overview ', this.props)

        const pageCount = 16

        const currentIndex = Number(this.props.match.params.index || 0)
        return (<div className="w3-panel" >


            <div className="w3-container" >

                <h1 className="w3-indigo" ><Link className="w3-button"
                                                 title="home"
                                                 to="/" ><i className="fas fa-arrow-left" /></Link>
                    <span className="w3-margin-left" >Browse</span></h1>
                <div className="w3-bar " >
                    <button className="w3-button w3-left"
                            onClick={() => {
                                RoutingUtils.push('/overview/0')
                            }}
                            title="go to first page" ><i className="fas fa-fast-backward" />
                    </button>
                    <button className="w3-button w3-left"
                            onClick={() => {
                                RoutingUtils.push(`/overview/${currentIndex - pageCount<0?SeedMachine.MAX_INT_RANGE-currentIndex - pageCount:currentIndex - pageCount }`)
                            }}
                            title="go to previous page" ><i className="fas fa-backward" />
                    </button>
                    <div className="w3-bar-item w3-center " >
                        <b>{Math.floor(currentIndex)}</b> to {Math.floor(currentIndex + pageCount)} of {Math.floor(SeedMachine.MAX_INT_RANGE / 16)}
                    </div>
                    <button className="w3-button w3-right"
                            onClick={() => {
                                RoutingUtils.push(`/overview/${currentIndex + pageCount }`)
                            }}
                            title="go to next page" ><i className="fas fa-fast-forward" />
                    </button>
                    <button className="w3-button w3-right"
                            onClick={() => {
                                RoutingUtils.push(`/overview/${SeedMachine.MAX_INT_RANGE - pageCount }`)
                            }}
                            title="go to last page" ><i className="fas fa-forward" />
                    </button>

                </div>

                <MandelRenderView count={pageCount}
                                  startIndex={this.props.match.params.index} />
            </div>
        </div>)
    }

}

export default (PageOverView)
