import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import HOCMandel from '../HOCMandel'
import PropTypes from 'prop-types'
import RenderMandelById from './RenderMandelById'

class MandelRenderView extends Component {

    static propTypes = {
        startIndex: PropTypes.number,
        color: PropTypes.oneOf(['black', 'white']),
        count: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        quality: PropTypes.number
    }
    static defaultProps = {
        startIndex: 0,
        count: 10,
        width: 200,
        height: 200,
        quality: 2,
        color: 'white',
        design: 'design1'
    }

    createItem = (index) => {
        return (<RenderMandelById
            {...this.props} index={index}

            key={'mandel' + index} />)
    }

    render() {
        console.log('Rendering Mandel View', this.props)

        const result = []

        for (var i = 0; i < this.props.count; i++) {
            result.push(this.createItem(i + Number(this.props.startIndex)))
        }
        return (
            <div>
            <div style={{
                display: 'grid',
                gridGap: '10px',
                gridTemplateColumns: this.props.count === 1 ? 'auto' : '25% 25% 25% 25%'
            }} >
                {result}
            </div>  </div>

        )
    }

}

export default HOCMandel()(MandelRenderView)
