var Constants = {

    COOKIES: {
        COOKIES_ACCEPTED: 'cookies-accept',
        GOOGLE_ANALYTICS_DEACTIVATED: 'ga-disable'
    },
    CLASSNAMES: {
        CONTACT_FORM_WRAPPER_OPEN: 'contactFormWrapperOpen',
        MAIN_WRAPPER: 'mainWrapper'
    },
    KEYS: {
        IS_COOKIE_NOTIFICATION_OPEN: 'isCookieNotificationOpen',
        IS_GOOGLE_ANALYTICS_ACTIVE: 'isGoogleAnalyticsActive',
        IS_MAIN_MENU_OPEN: 'isMainMenuOpen',
        IS_JOB_DESCRIPTION_OPEN: 'isJobDescriptionOpen',
        INDEX: 'index'
    },
    JOBSITEM: {
        LIST: 'list',
        TEXTBLOCK: 'textblock'
    },
    TRACKING: {
        GOOGLE_ANALYTICS_TRACKING_ID: 'UA-125132481-1'
    }
}

Constants.TRACKING.GOOGLE_ANALYTICS_TRACKING_ID = 'UA-125132481-1'

export default Constants
