import React, {Component} from 'react'
import './PageView.scss'
import PropTypes from 'prop-types'
import MandelRenderView from '../../../app/mandel/view/components/MandelRenderView'
// import {RoutingUtils} from 'ufp-core/lib/modules/react-redux-hash-router4/util'
// import {Link} from 'ufp-core/lib/modules/react-redux-hash-router4/view/components'
import RenderIndexInfo from '../../../app/mandel/view/components/RenderIndexInfo'
import qs from 'query-string'
import FacebookLike from '../../../components/FacebookLike'

export class PageView extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    }

    render() {
        const query = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})
        console.log('Rendering detail ', this.props, query, document.location)
     //   const pageCount = 1
        const currentIndex = Math.round(Number(this.props.match.params.index || 0))
        return (<div className="w3-panel" >
            <div className="  w3-display-container" >

                <MandelRenderView count={1}

                                  {...query}
                                  startIndex={currentIndex} />

                <div className="w3-bar w3-red" >
                    {/*<button className="w3-button w3-left"*/}
                    {/*onClick={() => {*/}
                    {/*RoutingUtils.push('/0')*/}
                    {/*}} ><i className="fas fa-fast-backward" />*/}
                    {/*</button >*/}
                    {/*<button className="w3-button w3-left "*/}
                    {/*onClick={() => {*/}
                    {/*RoutingUtils.push(`/${currentIndex - pageCount < 0 ? SeedMachine.MAX_INT_RANGE - currentIndex - pageCount : currentIndex - pageCount }`)*/}
                    {/*}} ><i className="fas fa-backward" />*/}
                    {/*</button >*/}
                    <RenderIndexInfo index={currentIndex} />
                    {/*<button className="w3-button w3-right"*/}
                    {/*onClick={() => {*/}
                    {/*RoutingUtils.push(`/${SeedMachine.MAX_INT_RANGE - pageCount}`)*/}
                    {/*}} ><i className="fas fa-fast-forward" />*/}
                    {/*</button >*/}
                    {/*<button className="w3-button w3-right"*/}
                    {/*onClick={() => {*/}
                    {/*RoutingUtils.push(`/${currentIndex + pageCount}`)*/}
                    {/*}} ><i className="fas fa-forward" />*/}
                    {/*</button >*/}

                </div >
                {/*<h1 className="w3-indigo " >*/}
                {/*<Link className="w3-button"*/}
                {/*to={'/overview/' + currentIndex} ><i className="fas fa-arrow-left" /></Link>*/}
                {/*<span className="w3-margin-left" >Index {currentIndex}</span>*/}
                {/*</h1>*/}

                <br />
                <FacebookLike />

            </div >
        </div >)
    }

}

export default (PageView)
