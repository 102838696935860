import React, {Component} from 'react'
import './HomeView.scss'
import {Link} from 'ufp-core/lib/modules/react-redux-hash-router4/view/components'
import FacebookLike from '../../../components/FacebookLike'
import RenderMandelById from '../../../app/mandel/view/components/RenderMandelById'
import RenderDateForIndex from '../../../app/mandel/view/components/RenderDateForIndex'
import CalcDaySeed from './CalcDaySeed'

export class HomeView extends Component {

    render() {
        return (<div className="w3-panel" >
            <div className="w3-container" >
                <h1 className="w3-indigo " >
                    <Link className="w3-button"
                          title="home sweet home"
                          to={'/'} ><i className="fas fa-home" /></Link>

                    <span className="w3-margin-left" >Today <RenderDateForIndex index={CalcDaySeed.todaysSeed()} /></span>
                    <Link className="w3-button w3-right"
                          title="browse"
                          to={'/overview'} ><i className="fas fa-arrow-right" /></Link>
                </h1>
                <div>
                    <RenderMandelById index={CalcDaySeed.todaysSeed()} />
                </div>
                <div>
                    <br />
                    <FacebookLike />
                </div>
            </div>
        </div>)
    }

}

export default (HomeView)
