import './styles/main.scss'
import UfpCore from 'ufp-core'
import UfpDemoApp from './app/Runfest'
import AppCreatorFunction from 'components/root/AppCreatorFunction'
import {ReactRunfest} from 'ufp-core/lib/modules/ufp-react'
import 'w3-css/3/w3.css'

UfpCore.registerRunfest(ReactRunfest)
UfpCore.registerRunfest(UfpDemoApp)

ReactRunfest.register({
    rootNode: document.getElementById('root'),
    appCreatorFunction: AppCreatorFunction
})

UfpCore.startupUfpCore()
