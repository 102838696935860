import {connect} from 'react-redux'
import MandelSelectors from '../MandelSelectors'

const mapStateToProps = (state) => ({
    mandel: MandelSelectors.MandelSelector(state)
})

const mapDispatchToProps = {}

export default () => connect(mapStateToProps, mapDispatchToProps)
