import {LOCATION_CHANGE} from 'react-router-redux'
import ReactGA from 'react-ga'

import {ofType} from 'redux-observable'
import {map} from 'rxjs/operators'

/**
 *
 * rxjs redux-observable epic please refer to
 * https://redux-observable.js.org/docs/basics/Epics.html
 *
 * this specific epic listens for the 'STARTUP' action and
 * triggers after 1000 milliseconds another action
 *
 * NOTE: this example IS NOT using short bigarrow notation for better
 * understanding the callbacks and what is going on
 */

var lastRoute = null
const changeRouteForGoogleAnalyticsEpic = (action$) => {
    console.log('changeRouterForGoogleAnalyticsEpic', action$)

    // do whatever your epic might do
    return action$.pipe(
        ofType(LOCATION_CHANGE),
        map((action) => () => {
            if (lastRoute !== action.payload.pathname) {
                console.log('ga-pageview', action.payload.pathname)
                ReactGA.pageview(action.payload.pathname)
                lastRoute = action.payload.pathname
            }
        }))
}

export default {
    changeRouteForGoogleAnalyticsEpic
}
