const Random = require('random-js')

function lerp(x, y, a) {
    return x * (1.0 - a) + y * a
}
const MAX_INT_RANGE = 0x7FFFFFFF
const seedMachine = function (valueIn) {
    // input value is integerized

    var mt = Random.engines.mt19937()
    mt.seed(Number(valueIn))

    if (valueIn < 0) {
        return []
    } else {
        // spit out 3 seeds according to incoming valuein
        return [
            {
                x: lerp(.00, .25, mt() / MAX_INT_RANGE),
                y: lerp(-.01000, .010, mt() / MAX_INT_RANGE)
            },
            {
                x: lerp(-.05, .05, mt() / MAX_INT_RANGE),
                y: lerp(-.07500, .07500, mt() / MAX_INT_RANGE)
            },
            {
                x: lerp(-.075, .075, mt() / MAX_INT_RANGE),
                y: lerp(-.07500, .07500, mt() / MAX_INT_RANGE)
            }

        ]
    }
}

module.exports = {
    MAX_INT_RANGE,
    seedMachine

}
