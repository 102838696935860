/**
 * the Runfest.js defines the properties of the ufp-module
 * @type {{name: string}}
 */
// import {MenuRunfest} from 'ufp-core/lib/modules/menu'
import MandelConstants from './MandelConstants'
import MandelReducer from './MandelReducer'

const Runfest = {
    name: 'ufp-mandel-i-love-chaos',
    description: 'Mandelbrot gui',

    onRegistered: ({UfpCore}) => {
        UfpCore.registerReducer({
            id: MandelConstants.NAME,
            reducer: MandelReducer

        })
    }

}

export default Runfest
