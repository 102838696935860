const RouteNames = {
    Home: '/',
    PageOverview: {
        route: '/overview/:index?',
        easy: '/overview'
    },
    PageDetailOld: '/:detail/:tag?/:index?',
    PageDetail: '/:index',
    Imprint: '/imprint'
}

export default RouteNames
