import React from 'react'
import CookieConsent2 from 'react-cookie-consent'

const CookieConsent = (props) => {
    console.log('Rendering menu', props)

    return (
        <CookieConsent2
                       buttonText="Confirm"
                       location="top" >
            This website uses cookies to enhance the user experience. <a href="#/imprint" >Privacy Declaration</a>
        </CookieConsent2>

    )
}
export default CookieConsent
