/* eslint-disable */
import React, {Component} from 'react'
import './PageView.scss'
import PropTypes from 'prop-types'

export class PageView extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    }

    render() {
        console.log('Rendering detail ', this.props)

        const pageCount = 1
        const currentIndex = Math.round(Number(this.props.match.params.index || 0))
        const currentTag = this.props.match.params.tag
        return (<div className="w3-panel" >

                <h2>Datenschutzerklärung</h2><h3 id="dsg-general-intro"  /><p>Diese Datenschutzerklärung klärt Sie
                über
                die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) im
                Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm
                verbundenen
                Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile
                auf
                (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten
                Begrifflichkeiten,
                wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
                Datenschutzgrundverordnung (DSGVO). <br />
                <br />
            </p><h3 id="dsg-general-controller" >Verantwortlicher</h3>
                <p><span className="tsmcontroller" >Christian Kleinhuis,<br />
Loestr 27<br />
53113, Bonn, Deutschland<br />
ck@digitalgott.de<br />
<br />
Link zum Impressum: http://ilc.fractalforums.com/#/imprint<br />
</span></p><h3 id="dsg-general-datatype" >Arten der verarbeiteten Daten</h3><p>- Bestandsdaten (z.B.,
                Personen-Stammdaten, Namen oder Adressen).<br />
                - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />
                - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />
                - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br />
                - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</p>
                <h3 id="dsg-general-datasubjects" >Kategorien betroffener Personen</h3><p>Besucher und Nutzer des
                Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).<br />
            </p><h3 id="dsg-general-purpose" >Zweck der Verarbeitung</h3><p>- Zurverfügungstellung des Onlineangebotes,
                seiner Funktionen und Inhalte.<br />
                - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />
                - Sicherheitsmaßnahmen.<br />
                - Reichweitenmessung/Marketing<br />
                <span className="tsmcom"  /></p><h3 id="dsg-general-terms" >Verwendete Begrifflichkeiten </h3><p>
                „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
                Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
                Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
                besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen,
                genetischen,
                psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.<br />
                <br />
                „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
                solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst
                praktisch jeden Umgang mit Daten.<br />
                <br />
                „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die
                personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen
                betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert
                aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten,
                dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen
                Person zugewiesen werden.<br />
                <br />
                „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
                besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte,
                die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich
                Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen,
                Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                analysieren oder vorherzusagen.<br />
                <br />
                Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung
                oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.<br />
                <br />
                „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder
                andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
                verarbeitet.<br />
            </p><h3 id="dsg-general-legalbasis" >Maßgebliche Rechtsgrundlagen</h3><p>Nach Maßgabe des Art. 13 DSGVO
                teilen
                wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
                Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der
                Datenschutzerklärung nicht genannt wird, Folgendes: <br />
                Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7
                DSGVO;<br />
                Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher
                Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;<br />
                Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6
                Abs. 1 lit. c DSGVO;<br />
                Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen
                Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO
                als Rechtsgrundlage.<br />
                Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im
                öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
                übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. <br />
                Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1
                lit. f DSGVO. <br />
                Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie ehoben wurden, bestimmt sich nach
                den Vorgaben des Art 6 Abs. 4 DSGVO. <br />
                Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich
                nach den Vorgaben des Art. 9 Abs. 2 DSGVO. <br />
            </p><h3 id="dsg-general-securitymeasures" >Sicherheitsmaßnahmen</h3><p>Wir treffen nach Maßgabe der
                gesetzlichen
                Vorgabenunter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des
                Umfangs,
                der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und
                Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische und
                organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.<br />
                <br />
                Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
                Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie
                betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
                Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung
                von Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
                personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie
                Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch
                datenschutzfreundliche Voreinstellungen.<br />
            </p><h3 id="dsg-general-coprocessing" >Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen
                und
                Dritten</h3><p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und
                Unternehmen
                (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln
                oder
                ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis
                (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung
                erforderlich ist), Nutzer eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf
                Grundlage
                unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). <br />
                <br />
                Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, übermitteln oder ihnen
                sonst den Zugriff gewähren, erfolgt dies insbesondere zu administrativen Zwecken als berechtigtes
                Interesse und darüberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage. <br />
            </p><h3 id="dsg-general-thirdparty" >Übermittlungen in Drittländer</h3><p>Sofern wir Daten in einem
                Drittland
                (d.h. außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
                Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
                Offenlegung, bzw. Übermittlung von Daten an andere Personen oder Unternehmen geschieht, erfolgt dies
                nur,
                wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund
                einer
                rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich
                gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland
                nur
                beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die Verarbeitung erfolgt z.B. auf Grundlage
                besonderer
                Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus
                (z.B.
                für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
                Verpflichtungen.</p><h3 id="dsg-general-rightssubject" >Rechte der betroffenen Personen</h3><p>Sie haben
                das
                Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft
                über
                diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
                Vorgaben.<br />
                <br />
                Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
                betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.<br />
                <br />
                Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten
                unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
                Einschränkung der Verarbeitung der Daten zu verlangen.<br />
                <br />
                Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns
                bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu erhalten und deren
                Übermittlung an andere Verantwortliche zu fordern. <br />
                <br />
                Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei
                der zuständigen Aufsichtsbehörde einzureichen.<br />
            </p><h3 id="dsg-general-revokeconsent" >Widerrufsrecht</h3><p>Sie haben das Recht, erteilte Einwilligungen
                mit
                Wirkung für die Zukunft zu widerrufen.</p><h3 id="dsg-general-object" >Widerspruchsrecht</h3><p><strong>Sie
                können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe der gesetzlichen Vorgaben
                jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
                Direktwerbung erfolgen.</strong></p><h3 id="dsg-general-cookies" >Cookies und Widerspruchsrecht bei
                Direktwerbung</h3><p>Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer
                gespeichert
                werden. Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient
                primär
                dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch
                nach
                seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw.
                „Session-Cookies“
                oder „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
                Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines
                Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als „permanent“ oder
                „persistent“
                werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B.
                der
                Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in
                einem
                solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder
                Marketingzwecke
                verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem
                Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen
                Cookies
                sind spricht man von „First-Party Cookies“).<br />
                <br />
                Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer
                Datenschutzerklärung auf.<br />
                <br />
                Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie
                gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren.
                Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der
                Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.<br />
                <br />
                Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten
                Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die
                US-amerikanische Seite
                <a href="http://www.aboutads.info/choices/" >http://www.aboutads.info/choices/</a>
                oder die EU-Seite <a href="http://www.youronlinechoices.com/" >http://www.youronlinechoices.com/</a>
                erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in
                den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls
                nicht alle Funktionen dieses Onlineangebotes genutzt werden können.</p>
                <h3 id="dsg-general-erasure" >Löschung von Daten</h3><p>Die von uns verarbeiteten Daten werden nach
                Maßgabe
                der gesetzlichen Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen
                dieser
                Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie
                für
                ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen
                Aufbewahrungspflichten
                entgegenstehen. <br />
                <br />
                Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
                erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht
                für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen
                Gründen aufbewahrt werden müssen.</p><h3 id="dsg-general-changes" >Änderungen und Aktualisierungen
                der Datenschutzerklärung</h3><p>Wir bitten Sie sich regelmäßig über den Inhalt unserer
                Datenschutzerklärung
                zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
                Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine
                Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung
                erforderlich wird.</p><p /><h3 id="dsg-commercialpurpose" >Geschäftsbezogene Verarbeitung</h3><p />
                <p><span className="ts-muster-content" >Zusätzlich verarbeiten wir<br />
-	Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).<br />
-	Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)<br />
von unseren Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher Leistungen, Service und Kundenpflege, Marketing, Werbung und Marktforschung.</span>
                </p><p /><h3 id="dsg-services-onlineshop" >Bestellabwicklung im Onlineshop und Kundenkonto</h3><p />
                <p><span className="ts-muster-content" >Wir verarbeiten die Daten unserer Kunden im Rahmen der Bestellvorgänge in unserem Onlineshop, um ihnen die Auswahl und die Bestellung der gewählten Produkte und Leistungen, sowie deren Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen.<br />
<br />
Zu den verarbeiteten Daten gehören Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten und zu den von der Verarbeitung betroffenen Personen gehören unsere Kunden, Interessenten und sonstige Geschäftspartner. Die Verarbeitung erfolgt zum Zweck der Erbringung von Vertragsleistungen im Rahmen des Betriebs eines Onlineshops, Abrechnung, Auslieferung und der Kundenservices. Hierbei setzen wir Session Cookies für die Speicherung des Warenkorb-Inhalts und permanente Cookies für die Speicherung des Login-Status ein.<br />
<br />
Die Verarbeitung erfolgt zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen (z.B. Durchführung von Bestellvorgängen) und soweit sie gesetzlich vorgeschrieben ist (z.B., gesetzlich erforderliche Archivierung von Geschäftsvorgängen zu Handels und Steuerzwecken). Dabei sind die als erforderlich gekennzeichneten Angaben zur Begründung und Erfüllung des Vertrages erforderlich. Die Daten offenbaren wir gegenüber Dritten nur im Rahmen der Auslieferung, Zahlung oder im Rahmen der gesetzlichen Erlaubnisse und Pflichten, als auch wenn dies auf Grundlage unserer berechtigten Interessen erfolgt, worüber wir Sie im Rahmen dieser Datenschutzerklärung informieren (z.B.,  gegenüber Rechts- und Steuerberatern, Finanzinstituten, Frachtunternehmen sowie Behörden). <br />
<br />
Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere ihre Bestellungen einsehen können. Im Rahmen der Registrierung, werden die erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen  notwendig. Angaben im Kundenkonto verbleiben bis zu dessen Löschung mit anschließender Archivierung im Fall einer rechtlichen Verpflichtung oder unser berechtigten Interessen (z.B., im Fall von Rechtsstreitigkeiten). Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern.<br />
<br />
Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer Onlinedienste, speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer gesetzlichen Ansprüche als berechtigtes Interesse erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung.<br />
<br />
Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs- und sonstiger vertraglicher Rechte oder Pflichten (z.B., Zahlungsansprüche oder Leistungspflichten aus Verträgen mir Kunden), wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im Fall der Aufbewahrung aufgrund gesetzlicher Archivierungspflichten, erfolgt die Löschung insoweit nach deren Ablauf.</span>
                </p><p /><h3 id="dsg-services-payment" >Externe Zahlungsdienstleister</h3><p />
                <p><span className="ts-muster-content" >Wir setzen externe Zahlungsdienstleister ein, über deren Plattformen die Nutzer und wir Zahlungstransaktionen vornehmen können (z.B., jeweils mit Link zur Datenschutzerklärung, Paypal (https://www.paypal.com/de/webapps/mpp/ua/privacy-full), Klarna (https://www.klarna.com/de/datenschutz/), Skrill (https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/), Giropay (https://www.giropay.de/rechtliches/datenschutz-agb/), Visa (https://www.visa.de/datenschutz), Mastercard (https://www.mastercard.de/de-de/datenschutz.html), American Express (https://www.americanexpress.com/de/content/privacy-policy-statement.html)<br />
<br />
Im Rahmen der Erfüllung von&nbsp;Verträgen setzen wir die Zahlungsdienstleister auf Grundlage des Art. 6 Abs. 1 lit. b. DSGVO ein. Im Übrigen setzen wir externe&nbsp;
                    Zahlungsdienstleister auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO ein, um unseren Nutzern effektive und sichere Zahlungsmöglichkeit zu bieten.<br />
<br />
Zu den, durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen und empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert. D.h. wir erhalten keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung.&nbsp;
                    Unter Umständen werden die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und Datenschutzhinweise der&nbsp;
                    Zahlungsdienstleister.<br />
<br />
Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten, bzw. Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</span>
                </p><p /><h3 id="dsg-hostingprovider" >Hosting und E-Mail-Versand</h3><p />
                <p><span className="ts-muster-content" >Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen. <br />
<br />
Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).</span>
                </p><p /><h3 id="dsg-cdn-cloudflare" >Content-Delivery-Network von Cloudflare</h3><p />
                <p><span className="ts-muster-content" >Wir setzen ein so genanntes "Content Delivery Network" (CDN), angeboten von Cloudflare, Inc., 101 Townsend St, San Francisco, CA 94107, USA, ein. Cloudflare ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active"
                                                                                                                                                                                                                                                                                                                                                         target="_blank" >https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;
                    status=Active</a>).<br />
<br />
Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte unseres Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder Skripte mit Hilfe regional verteilter und über das Internet verbundener Server, schneller ausgeliefert werden. Die Verarbeitung der Daten der Nutzer erfolgt alleine zu den vorgenannten Zwecken und der Aufrechterhaltung der Sicherheit und Funktionsfähigkeit des CDN.<br />
<br />
Die Nutzung erfolgt auf Grundlage unserer berechtigten Interessen, d.h. Interesse an einer sicheren und effizienten Bereitstellung, Analyse sowie Optimierung unseres Onlineangebotes gem. Art. 6 Abs. 1 lit. f. DSGVO. <br />
<br />
Weitere Informationen erhalten Sie in der Datenschutzerklärung von Cloudflare:&nbsp;<a href="https://www.cloudflare.com/security-policy"
                                                                                       target="_blank" >https://www.cloudflare.com/security-policy</a>.</span>
                </p><p /><h3 id="dsg-ga-googleanalytics" >Google Analytics</h3><p />
                <p><span className="ts-muster-content" >Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.<br />
<br />
Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                                                                                                                                               target="_blank" >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;
                        status=Active</a>).<br />
<br />
Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.<br />
<br />
Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.<br />
<br />
Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren:&nbsp;
                    <a href="http://tools.google.com/dlpage/gaoptout?hl=de"
                       target="_blank" >http://tools.google.com/dlpage/gaoptout?hl=de</a>.<br />
<br />
Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads"
                                                                                                                                                          target="_blank" >https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                                                                                                                                                                                                                                                                                                                                                       target="_blank" >(https://adssettings.google.com/authenticated</a>).<br />
<br />
Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.</span></p><p />
                <h3 id="dsg-ga-universal" >Google Universal Analytics</h3><p /><p><span className="ts-muster-content" >Wir setzen Google Analytics in der Ausgestaltung als „<a href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376"
                                                                                                                                                                              target="_blank" >Universal-Analytics</a>“ ein. „Universal Analytics“ bezeichnet ein Verfahren von Google Analytics, bei dem die Nutzeranalyse auf Grundlage einer pseudonymen Nutzer-ID erfolgt und damit ein pseudonymes Profil des Nutzers mit Informationen aus der Nutzung verschiedener Geräten erstellt wird (sog. „Cross-Device-Tracking“).</span>
            </p><p /><h3 id="dsg-tracking-adsense-personalized" >Google Adsense mit personalisierten Anzeigen</h3>
                <p />
                <p><span className="ts-muster-content" >Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, („Google“).<br />
<br />
Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                                                                                                                                               target="_blank" >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;
                        status=Active</a>).<br />
<br />
Wir Nutzen den Dienst AdSense, mit dessen Hilfe Anzeigen in unsere Webseite eingeblendet und wir für deren Einblendung oder sonstige Nutzung eine Entlohnung erhalten. Zu diesen Zwecken werden Nutzungsdaten, wie z.B. der Klick auf eine Anzeige und die IP-Adresse der Nutzer verarbeitet, wobei die IP-Adresse um die letzten beiden Stellen gekürzt wird. Daher erfolgt die Verarbeitung der Daten der Nutzer pseudonymisiert. <br />
<br />
Wir setzen Adsense mit personalisierten Anzeigen ein. Dabei zieht Google auf Grundlage der von Nutzern besuchten Websites oder verwendeten Apps und den so erstellten Nutzerprofilen Rückschlüsse auf deren Interessen. Werbetreibende nutzen diese Informationen, um ihre Kampagnen an diesen Interessen auszurichten, was für Nutzer und Werbetreibende gleichermaßen von Vorteil ist. Für Google sind Anzeigen dann personalisiert, wenn erfasste oder bekannte Daten die Anzeigenauswahl bestimmen oder beeinflussen. Dazu zählen unter anderem frühere Suchanfragen, Aktivitäten, Websitebesuche, die Verwendung von Apps, demografische und Standortinformationen. Im Einzelnen umfasst dies: demografisches Targeting, Targeting auf Interessenkategorien, Remarketing sowie Targeting auf Listen zum Kundenabgleich und Zielgruppenlisten, die in DoubleClick Bid Manager oder Campaign Manager hochgeladen wurden.<br />
<br />
Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads"
                                                                                                                                                          target="_blank" >https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                                                                                                                                                                                                                                                                                                                                                       target="_blank" >(https://adssettings.google.com/authenticated</a>).<br />
</span></p><p /><h3 id="dsg-tracking-adwords" >Google AdWords und Conversion-Messung</h3><p />
                <p><span className="ts-muster-content" >Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, („Google“).<br />
<br />
Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;
                    status=Active).<br />
<br />
Wir nutzen das Onlinemarketingverfahren Google "AdWords", um Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein mutmaßliches Interesse an den Anzeigen haben. Dies erlaubt uns Anzeigen für und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist, unmittelbar durch Google ein Code von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als "Web Beacons" bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies können auch vergleichbare Technologien verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, für welche Inhalte er sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes.<br />
<br />
Ferner erhalten  wir ein individuelles „Conversion-Cookie“. Die mit Hilfe des Cookies eingeholten Informationen dienen Google dazu, Conversion-Statistiken für uns zu erstellen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.<br />
    <br />
Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen nicht für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu verarbeiten. Die über die Nutzer gesammelten Informationen werden an Google übermittelt und auf Googles Servern in den USA gespeichert.<br />
<br />
Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads"
                                                                                                                                                          target="_blank" >https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                                                                                                                                                                                                                                                                                                                                                       target="_blank" >(https://adssettings.google.com/authenticated</a>).</span>
                </p><a className="dsg1-6"
                       href="https://datenschutz-generator.de"
                       rel="nofollow"
                       target="_blank" >Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a>
            </div>
        )
    }

}

export default (PageView)
