import React from 'react'
import AdSense from 'react-adsense'

const GoogleAdsense = (props) => {
    console.log('Rendering menu', props)

    return (
        <AdSense.Google client="ca-pub-4885104155050142"
                        format="auto"
                        responsive="true"
                        slot="1225921494"
                        style={{display: 'block'}} />

    )
}
export default GoogleAdsense
