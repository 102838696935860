import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import HOCMandel from '../HOCMandel'
import PropTypes from 'prop-types'

function pad(number, length) {
    var str = '' + number
    while (str.length < length) {
        str = '0' + str
    }

    return str
}
class RenderMandelById extends Component {

    static propTypes = {
        index: PropTypes.number

    }
    static defaultProps = {
        index: 0
    }

    render() {
        const {index}=this.props
        const year = Math.floor(index / (12 * 31))
        const yearrest = index % (12 * 31)
        const month = Math.floor(yearrest / 31)
        const monthrest = yearrest % 31
        return (<span>{pad(year,4)}-{pad(month+1,2) }-{pad(monthrest + 1,2)}</span>)
    }

}

export default HOCMandel()(RenderMandelById)
