import React, {Component} from 'react'
import ReactFBLike from 'react-fb-like'
// import PropTypes from 'prop-types'

export class FacebookLike extends Component {

    static propTypes = {
        // url: PropTypes.string
    }

    static defaultProps = {

        url: undefined

    }

    componentDidMount() {
        //console.log("PARSING FB FB mount",window.FB)
        if (window.FB && window.FB.XFBML) {
            //console.log("PARSING FB FB settimeout",window.FB)
            setTimeout(() => {
                // console.log("PARSING FB FB")
                window.FB.XFBML.parse()
            }, 100)
        }
    }

    render() {
        console.log('Rendering fb ', window.location)

        return (<ReactFBLike appId="2153336398239436"
                             url={'http://ilc.fractalforums.com/#/'} />
        )
    }

}

export default (FacebookLike)
