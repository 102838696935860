const initialState = {

    location: {
        zoom: 1.0,
        center: {
            x: 0.0,
            y: 0.0
        }
    }

}

export default(state=initialState) => {
    return state
}
