/**
 * the Runfest.js defines the properties of the ufp-module
 * @type {{name: string}}
 */
import MandelRunfest from './mandel/Runfest'
import Constants from './Constants'
import GoogleAnalyticsRunfest from './googleanalytics/Runfest'
import {Router4Runfest} from 'ufp-core/lib/modules/react-redux-hash-router4'
// import MenuConfigFile from './MenuConfigFile'

const Runfest = {
    name: '001-ufp-minimal-request-app',
    description: 'Example Ufp Application, configures middleware and loads static .json file into configuration reducer',

    onRegistered: ({UfpCore}) => {
        // Runfest registering enables rewriting of this Runfest to bound actionCreators and bound selectors

        // Enable Ufp Middleware For this module

        UfpCore.registerRunfest(Router4Runfest)
        GoogleAnalyticsRunfest.configure(Constants.TRACKING)
        UfpCore.registerRunfest(GoogleAnalyticsRunfest)

        UfpCore.registerRunfest(MandelRunfest)

        //     createMenu(MenuConfigFile)
    }

}

export default Runfest
